import React from "react"
import { StoryGroup } from "components"

export const LinkMap = {
  "OnurDayibasi.dev": "f9fecb939d1",
  Experiment: "f9fecb939d1",
  Lab: "f9fecb939d1",
  Live: "f9fecb939d1",

  "OnurDayibasi.com": "5634ce97997f",
  Blog: "5634ce97997f",
  JavaScript: "5634ce97997f",
  Frontend: "5634ce97997f",
  React: "5634ce97997f",
  "Software Architecture": "5634ce97997f",
  Other: "5634ce97997f",

  "Why Do We Write super(props)": "b10a759a73fc",
  "How Does React Tell a Class from a Function?": "b10a759a73fc",
  "Why Do React Elements Have a $$typeof Property?": "b10a759a73fc",
  "My Wishlist for Hot Reloading": "b10a759a73fc",
  "How Does setState Know What to Do?": "b10a759a73fc",
  "Optimized for Change?": "b10a759a73fc",
  "Why Do React Hooks Rely on Call Order?": "b10a759a73fc",
  "The Elements of UI Engineering?": "b10a759a73fc",
  "The “Bug-O” Notation": "b10a759a73fc",
  "Why Isn’t X a Hook?": "b10a759a73fc",
  "React as a UI Runtime": "b10a759a73fc",
  "Making setInterval Declarative with React Hooks": "b10a759a73fc",
  "Fix Like No One’s Watching": "b10a759a73fc",
  "Coping with Feedback": "b10a759a73fc",
  "How Are Function Components Different from Classes?": "b10a759a73fc",
  "A Complete Guide to useEffect": "b10a759a73fc",
  "Writing Resilient Components": "b10a759a73fc",
  "Name It, and They Will Come": "b10a759a73fc",
  "Algebraic Effects for the Rest of Us": "b10a759a73fc",
  "How Does the Development Mode Work?": "b10a759a73fc",
  "What Is JavaScript Made Of?": "b10a759a73fc",
  "On let vs const": "b10a759a73fc",
  "What Are the React Team Principles?": "b10a759a73fc",
  "My Decade in Review": "b10a759a73fc",
  "Goodbye, Clean Code": "b10a759a73fc",
  "The WET Codebase": "b10a759a73fc",
}

const stories = [
  {
    title: "OnurDayibasi.com - Frontend Dev Notes",
    postId: "5634ce97997f",
  },
  {
    title: "OnurDayibasi.dev - React Digital Garden",
    postId: "f9fecb939d1",
  },
  {
    title: "LearnReactUI.dev - React Digital Ebooks and Samples",
    postId: "5530d5e347d4",
  },
  {
    title: "Overreacted.io - Dan Abramov Blog",
    postId: "b10a759a73fc",
  },
]

export const context = {
  stories: stories,
  linkMap: LinkMap,
  title: "Faydali Blog Analizleri",
  path: "faydali-blog-analizleri",
}

const BlogAnalysisPage = () => {
  return (
    <StoryGroup
      title={context.title}
      stories={context.stories}
      linkMap={context.linkMap}
    />
  )
}

export default BlogAnalysisPage
